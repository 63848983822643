@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css)

/*------------------
 * カラーリング設定
 *------------------
$c-p: #000
$c-s: #333
$c-b: #F7F7F7
$c-000: #000
$c-333: #333
$c-7c: #7c7c7c
$c-ac: #acacac
$c-ff: #fff
$c-000-s: rgba(0, 0, 0, 0.2)
$c-000-s: rgba(51, 51, 51, 0.2)

/*------------------
 * ブレイクポイント設定
 *------------------
/* pc
$pc-max: 1024px
$pc-min: 1025px

/* tb
$tb-max: 959px
$tb-min: 960px

/* sp-tb
$sp-tb-max: 768px
$sp-tb-min: 769px

/* sp-sp-tb
$sp-sp-tb-max: 635px
$sp-sp-tb-min: 634px

/* sp
$sp-max: 559px
$sp-min: 560px

/*------------------
 * 時間設定
 *------------------
$transition-time: 0.3s

/*------------------
 * 基礎設定
 *------------------

*,html
  box-sizing: border-box

html
  @media screen and (min-width: $pc-min)
    font-size: 18px

  @media screen and (max-width: $pc-max)
    font-size: 16px

  @media screen and (max-width: $tb-max)
    font-size: 16px

  @media screen and (max-width: $sp-max)
    font-size: 16px

body
  position: relative
  overflow-x: hidden
  overflow-y: auto
  min-width: 300px
  width: 100%
  >#root
    display: flex
    flex-flow: column
    position: relative
    min-height: 100vh
    width: 100%

.body-wrapper
  flex: 1
  >.page-container
    padding: 25px 25px 60px 25px
    &.detail-layout
      min-height: 100vh
      padding: 81px 25px 60px 25px

a
  font-size: 1rem
  color: $c-000
  text-decoration: none
  transition: $transition-time

h1,h2,h3,h4,h5,h6
  font-family: 'YuGothic', sans-serif
  font-weight: 600

p, a
  font-family: 'Noto Sans JP', sans-serif
  font-weight: 400

h1,h2, h3, h4, h5, p, a
  line-height: 1.5
  letter-spacing: 0.025rem

h1
  font-size: 1.375rem
h2
  font-size: 1.25rem
h2
  font-size: 1.125rem
h3
  font-size: 1.0rem
h4
  font-size: 1.00rem
h5
  font-size: 1.00rem
h6
  font-size: 1.00rem
p
  font-size: 1.00rem

/* テキストによく使う記述
.txt-center
  text-align: center

.txt-left
  text-align: left

.txt-right
  text-align: right

.weight-light
  font-weight: lighter

.weight-normal
  font-weight: normal

.weight-bold
  font-weight: bold

input
  font-size: 1.125rem!important

form input
  width: 100%

img
  pointer-events: none

/*------------------
 *     マージン
 *------------------

@for $i from 0 through 30
  .mb#{$i * 5}
    margin-bottom: 5px * $i!important

@media screen and (max-width: $sp-tb-max)
  @for $i from 0 through 30
    .sp-tb-mb#{$i * 5}
      margin-bottom: 5px * $i!important


@for $i from 0 through 30
  .mt#{$i * 5}
    margin-top: 5px * $i!important

@media screen and (max-width: $sp-tb-max)
  @for $i from 0 through 30
    .sp-tb-mt#{$i * 5}
      margin-top: 5px * $i!important

@for $i from 0 through 30
  .ml#{$i * 5}
    margin-left: 5px * $i!important

@media screen and (max-width: $sp-tb-max)
  @for $i from 0 through 30
    .sp-tb-ml#{$i * 5}
      margin-left: 5px * $i!important

@for $i from 0 through 30
  .mr#{$i * 5}
    margin-right: 5px * $i!important

@media screen and (max-width: $sp-tb-max)
  @for $i from 0 through 30
    .sp-tb-mr#{$i * 5}
      margin-right: 5px * $i!important

/*------------------
 *     PC/SPでの表示
 *------------------

@media screen and (max-width: $sp-max)
  .sp-high
    display: none !important

@media screen and (min-width: $sp-min)
  .sp-low
    display: none !important

@media screen and (max-width: $sp-tb-max)
  .sp-tb-high
    display: none !important

@media screen and (min-width: $sp-tb-min)
  .sp-tb-low
    display: none !important

@media screen and (max-width: $pc-max)
  .pc-high
    display: none !important

@media screen and (min-width: $pc-min)
  .pc-low
    display: none !important

/*------------------
 *     ボタン
 *------------------

body button.next-btn,body a.next-btn
  display: block
  text-align: center
  background-color: #00B7CE
  border-radius: 100px
  font-size: 1rem
  font-weight: bold
  padding: 10px 20px
  &:hover,
  &:active
    background-color: #00B7CE
